import React from "react";
import "./style/serviceCard.css";
import { Grid } from "@mui/material";

const ServiceCard = ({
  title1,
  heading,
  description1,
  title2,
  description2,
  button,
  image,
  style,
  titleStyle,
  onClick,
  customheading,
  customtitle,
  customdescription,
}) => {
  return (
    <div style={style}>
      <Grid container spacing={1} className="Quuote">
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <div className="Quuote_ompetitive_title" style={titleStyle}>
            {heading}
          </div>
          <div className=" Quuote_ompetitive_title2" style={titleStyle}>
            {customheading}
          </div>
          <div className="Quuote_ompetitive_des2">{customtitle}</div>
          <div className="Quuote_ompetitive_des2">{customdescription}</div>
          <div className="Quuote_ompetitive_des">{title1}</div>
          <div className="Quuote_ompetitive_des">{description1}</div>
          <div className="Quuote_ompetitive_des">{title2}</div>
          <div className="Quuote_ompetitive_des">{description2}</div>
          {button && (
            <a style={{ textDecoration: "none" }}>
              <button className="Quuote_ompetitive_button" onClick={onClick}>
                {button}
              </button>
            </a>
          )}
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <img className="service_card_img" src={image} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceCard;
