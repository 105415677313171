import React from "react";
import "./style/integrationTable.css";
import { Grid } from "@mui/material";

const IntegrationTable = ({ mainHeading, table_heading }) => {
  return (
    <Grid sx={{ width: "91%", margin: "auto" }}>
      <div className="main_heading">{mainHeading}</div>

      <div className="table-container">
        <table className="responsive-table">
          <thead>
            <tr style={{ backgroundColor: "#00d795" }}>
              <th style={{ backgroundColor: "white", border: "none" }}></th>

              <th style={{ width: "50%", color: "white" }}>{table_heading}</th>
            </tr>
          </thead>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6", color: "#3e68a1" }}>
              <th className="table_heading">ORDERS</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb"> Get orders instantly</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)" }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Get orders randomly (10 min)</td>

              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(172, 18, 17)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6", color: "#3e68a1" }}>
              <th className="table_heading">CHECK-OUT</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb">Check-out: Flat price</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Check-out: Shipping Cost rules</td>

              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">
                Check-out: Customer select carrier's shipping cost
              </td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6", color: "#3e68a1" }}>
              <th className="table_heading"> MANAGE ORDERS</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb">Manage Edit order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Manage Cancel order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Update Tracking to order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </Grid>
  );
};

export default IntegrationTable;
