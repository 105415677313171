import React from "react";
import "./style/integrationTable.css";
import { Grid } from "@mui/material";

const IntegrationTable = ({ mainheadingStyle }) => {
  return (
    <Grid sx={{ width: "91%", margin: "auto" }}>
      <div className="main_heading">Shopify Integration</div>

      <div className="table-container">
        <table className="responsive-table">
          <thead>
            <tr style={{ backgroundColor: "#00d795" }}>
              <th
                style={{ backgroundColor: "white", border: "none" }}
                className="hide"
              ></th>

              <th style={{ width: "50%", color: "white" }}>Shopify</th>
            </tr>
          </thead>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6" }}>
              <th className="heading">ORDERS</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb">Get orders instantly</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    color: "rgb(33, 201, 45)",
                    fontSize: 18,
                    backgroundColor: "#ededed",
                  }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Get orders randomly (10 min)</td>

              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 352 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    color: "rgb(172, 18, 17)",
                    fontSize: 18,
                    backgroundColor: "#ededed",
                  }}
                >
                  <title>Feature Not Included</title>
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6" }}>
              <th className="heading">CHECK-OUT</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb">Check-out: Flat price</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Check-out: Shipping Cost rules</td>

              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">
                Check-out: Customer select carrier's shipping cost
              </td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
          <thead>
            <tr style={{ backgroundColor: "#f6f6f6" }}>
              <th className="heading"> MANAGE ORDERS</th>
              <th style={{ width: "50%" }}></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_tb">Manage Edit order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Manage Cancel order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            <tr>
              <td className="table_tb">Update Tracking to order</td>
              <td
                style={{
                  backgroundColor: "#ededed",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(33, 201, 45)", fontSize: 18 }}
                >
                  <title>Feature Included</title>
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </Grid>
  );
};

export default IntegrationTable;
