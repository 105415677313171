import { Card, Grid, Link } from "@mui/material";
import React from "react";
import "./style/plane.css";

const Plane = () => {
  return (
    <>
      <div className="plane_container">
        <div className="plane_black_effect"></div>
        <div className="container_data">
          <Grid sx={{ width: "90%", margin: "auto" }}>
            <div className="plane_title">Our Plans!</div>
            <div className="plane_des">
              Do you have your own carrier's account with DHL, FEDEX, UPS,
              etc..., but you are wasting time to check individually each
              carrier's rates?
            </div>

            <button
              className="plane_button"
              onClick={() => {
                window.location.href = "https://app.deleveryday.com/";
              }}
            >
              Try Now
            </button>
          </Grid>
        </div>
      </div>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
          pt: 10,
          pb: 10,
        }}
      >
        <div className="plane_card_conatiner">
          <Card
            elevation={4}
            sx={{ borderRadius: "20px" }}
            className="plane_box_inner"
          >
            <div style={{ padding: 20 }}>
              <div
                style={{
                  fontSize: 15,
                  backgroundColor: "black",
                  width: "20%",
                  color: "white",
                  borderRadius: 20,
                  padding: 5,
                  textAlign: "center",
                }}
              >
                Popular
              </div>
              <div
                style={{
                  fontSize: 19,
                  marginTop: 10,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                15 DAYS FREE TRAIL
              </div>
              <div
                style={{
                  fontSize: 40,
                  marginTop: 20,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                $9.99
                <span style={{ fontSize: "15px", fontWeight: "400" }}>
                  / per month
                </span>
              </div>
              <Link
                onClick={() => {
                  window.location.href = "https://app.deleveryday.com/";
                }}
                style={{
                  fontSize: 14,
                  marginTop: 22,
                  color: "black",
                  fontWeight: "500",
                  width: "94%",
                  display: "inline-block",
                  backgroundColor: "#10D48E",
                  color: "white",
                  border: "none",
                  borderRadius: 5,
                  padding: 10,
                  textAlign: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                CLAIM YOUR TRIAL
              </Link>
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                No credit card required
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  Unlimited shipments
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="33"
                  height="29"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  Discounted rates up to 70% on selected carriers & routes
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  E-Commerce integration
                </div>
              </div>
            </div>
          </Card>
          <Card
            elevation={4}
            sx={{ borderRadius: "20px" }}
            className="plane_box_inner"
          >
            <div style={{ padding: 20 }}>
              <div
                style={{
                  fontSize: 19,
                  marginTop: 10,
                  color: "black",
                  fontWeight: "500",
                  fontFamily: "poppins",
                }}
              >
                GROWING PLAN
              </div>
              <div
                style={{
                  fontSize: 40,
                  marginTop: 45,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                $49
                <span style={{ fontSize: "15px", fontWeight: "400" }}>
                  / per month
                </span>
              </div>
              <button
                style={{
                  fontSize: 14,
                  marginTop: 30,
                  color: "black",
                  fontWeight: "500",
                  width: "100%",
                  backgroundColor: "#10D48E",
                  color: "white",
                  border: "none",
                  borderRadius: 5,
                  fontFamily: "poppins",
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                COMING SOON
              </button>
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                All FREE Plan Plus
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  Add your own carrier (max 2)
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  Shipping Rules
                </div>
              </div>
            </div>
          </Card>
          <Card
            elevation={4}
            sx={{ borderRadius: "20px" }}
            className="plane_box_inner"
          >
            <div style={{ padding: 20 }}>
              <div
                style={{
                  fontSize: 19,
                  marginTop: 10,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                PRO PLAN
              </div>
              <div
                style={{
                  fontSize: 40,
                  marginTop: 45,
                  color: "black",
                  fontWeight: "500",
                }}
              >
                $149
                <span style={{ fontSize: "15px", fontWeight: "400" }}>
                  / per month
                </span>
              </div>
              <button
                style={{
                  fontSize: 14,
                  marginTop: 30,
                  color: "black",
                  fontWeight: "500",
                  width: "100%",
                  backgroundColor: "#10D48E",
                  color: "white",
                  border: "none",
                  borderRadius: 5,
                  padding: 10,
                  fontFamily: "poppins",
                  cursor: "pointer",
                }}
              >
                COMING SOON
              </button>
              <div style={{ marginTop: 20, marginBottom: 10 }}>
                All FREE Plan and Growing Plan Plus
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  color="#10D48E"
                  class="flex-shrink-0 w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m5 13 4 4L19 7"
                  ></path>
                </svg>
                <div style={{ marginLeft: 5, fontSize: 14 }}>
                  Add your own carrier (unlimited)
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Grid>
    </>
  );
};

export default Plane;
