import React from "react";
import { Grid } from "@mui/material";
import IntegrationTable2 from "../components/IntegrationTable2";
import WooImg from "../assets/woo1.png";
import { TbPackages } from "react-icons/tb";
import { RiSecurePaymentFill } from "react-icons/ri";
import { FaSackDollar } from "react-icons/fa6";
import "./style/shopify.css";
import { Link } from "react-router-dom";
const WooCommerce = () => {
  return (
    <div>
      <div className="shopify_container">
        <Grid container spacing={1} className="shopify_inner_container">
          <div
            className="main_section"
            style={{ width: "90%", margin: "auto", display: "flex" }}
          >
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <div className="shopify_title">
                Shipping for WooCommerce Stores is powered by Deleveryday
              </div>
              <div className="shopify_des">
                Trust your shipping with a specialized shipping and fulfillment
                platform
              </div>
              <div className="try_now_btn">
                <Link to="#">Try Now</Link>
              </div>
            </Grid>
            <Grid
              className="main_section_img"
              sx={{ display: "flex", alignItems: "center" }}
              item
              xl={6}
              lg={6}
              md={6}
              xs={12}
            >
              <div className="pages_img">
                <img width="80%" className="shopify_landing_img" src={WooImg} />
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
      {/* ___________________________________________Shopify Shipping_________________________________________________ */}

      <div className="shipping_conatiner">
        <Grid className="shipping_inner_container">
          <Grid item xl={6} lg={6} md={6} xs={12} style={{ paddingBottom: 30 }}>
            <div className="shipping_heading">
              Level Up Your WooCommerce Shipping
            </div>
            <div className="shipping_title">
              WooCommerce vendors appreciate that we assist them automate
              shipping for successful business results.
            </div>
            <div className="shipping_des">
              Revolutionize your WooCommerce shipping with Deleveryday.
              Effortlessly integrate your WooCommerce account for seamless and
              transparent logistics. As a trusted partner for WooCommerce
              businesses, Deleveryday ensures swift and secure shipping
              solutions. Simplify order fulfillment, optimize your supply chain,
              and experience the convenience of connecting your WooCommerce
              account with Deleveryday for a streamlined and reliable shipping
              experience.
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          xs={12}
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <IntegrationTable2
            mainHeading={"WooCommerce Integrations"}
            table_heading={"WooCommerce"}
          />
        </Grid>
        {/* ___________________________________________Unlock Global Growth_________________________________________________ */}

        <Grid
          container
          className="shopify_global_inner"
          sx={{ width: "91%", margin: "auto" }}
        >
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <img
              className="card_img"
              src={
                "https://www.rapyd.net/wp-content/uploads/2020/04/iStock-1140691225-e1586632860203.jpg"
              }
            />
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="page_heading"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Unlock Global Growth
            </div>
            <div
              className="page_des"
              style={{
                color: "black",
                marginTop: 25,
                marginBottom: 30,
              }}
            >
              Allow our clients to shop in your Shopify store as if they were
              locals. Our website provides the necessary shipping papers, and
              our courier partners ensure that your packages arrive securely.
            </div>
            <a
              target="_blank"
              href="https://app.deleveryday.com/"
              style={{ textDecoration: "none" }}
            >
              <button
                className="page_des"
                style={{
                  padding: 15,
                  backgroundColor: " #10D48E",
                  color: "#ffffff",
                  borderRadius: 8,
                  cursor: "pointer",
                  border: "none",
                }}
                onClick={() => {
                  window.location.href = "https://app.deleveryday.com/";
                }}
              >
                Get Started For Free
              </button>
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          className="shopify_global_inner2"
          sx={{ width: "91%", margin: "auto", paddingBottom: 10 }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="page_heading"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Biggest Range Of Couriers
            </div>
            <div
              className="page_des"
              style={{
                color: "black",
                marginTop: 25,
                marginBottom: 30,
              }}
            >
              Don't be constrained by a few couriers. Deleveryday gives you
              access to 250+ couriers services and the cheapest rates for any
              destination country, regardless of what or where you are
              delivering.
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <img
              className="card_img2"
              width="100%"
              borderRadius="30px"
              height="300px"
              src={
                "https://www.shutterstock.com/image-photo/above-table-top-view-female-600nw-1831476562.jpg"
              }
            />
          </Grid>
        </Grid>

        <div style={{ width: "100%", background: "#45494E" }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sx={{
              width: "91%",
              margin: "auto",
            }}
            className="shopify_service_conatiner"
          >
            <div className="shopify_service_inner_conatiner">
              <div style={{ width: "100%" }}>
                <div
                  className="shopify_service_card"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "top",
                    flexDirection: "column",
                    borderRadius: "20px",
                    padding: "15px",
                    background: "shadow",
                    boxShadow: "0.5px 0.5px 10px #fff,-0.5px -0.5px 10px #fff",
                  }}
                >
                  <span style={{ fontSize: "45px", color: "#fff" }}>
                    <TbPackages />
                  </span>
                  <div className="shopify_service_title">
                    Biggest Range Of Couriers Accurate Shipping Rates at
                    Checkout
                  </div>
                  <div className="shopify_service_des">
                    Instead of estimating shipping costs, give your consumers
                    the option of choosing the fastest, cheapest, or greatest
                    value for money, with real pricing, directly from your
                    Shopify store. Furthermore, Deleveryday allows you to apply
                    Free Shipping rules to assist promote conversions.
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="shopify_service_card"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "top",
                    flexDirection: "column",
                    borderRadius: "20px",
                    padding: "15px",
                    background: "shadow",
                    boxShadow: "0.5px 0.5px 10px #fff,-0.5px -0.5px 10px #fff",
                  }}
                >
                  <span style={{ fontSize: "45px", color: "#fff" }}>
                    <RiSecurePaymentFill />
                  </span>
                  <div className="shopify_service_title">
                    Pre-Pay Duties & Taxes for Seamless Delivery
                  </div>
                  <div className="shopify_service_des">
                    Import tariffs and taxes can be a significant hardship when
                    shipping overseas. We provide complete transparency into
                    projected import taxes, tariffs, and fees, and even allow
                    you to pre-pay these costs to assure on-time delivery. Rates
                    at Checkout are fully compatible.
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div
                  className="shopify_service_card"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "top",
                    flexDirection: "column",
                    borderRadius: "20px",
                    padding: "15px",
                    background: "shadow",
                    boxShadow: "0.5px 0.5px 10px #fff,-0.5px -0.5px 10px #fff",
                  }}
                >
                  <span
                    style={{
                      marginTop: "10px",
                      fontSize: "45px",
                      color: "#fff",
                    }}
                  >
                    <FaSackDollar />
                  </span>
                  <div className="shopify_service_title">
                    Affordable And Fair Insurance
                  </div>
                  <div className="shopify_service_des">
                    Insurance supplied by couriers is hit-or-miss. Our shipment
                    insurance is reasonably priced, and the claim time is far
                    shorter than that supplied by couriers.
                  </div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid sx={{ pl: 4, pr: 4 }} style={{ backgroundColor: "#10D48E" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: 50,
                paddingTop: 50,
              }}
            >
              <div className="store_Need_title">
                We Have What WooCommerce Stores Need
              </div>
              <div className="store_Need_heading">
                We created a connection with Shopify that allows you to enjoy
                the benefits of Deleveryday without disrupting the business
                flows you've created on the Shopify platform.
              </div>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default WooCommerce;
