import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Plane from "./pages/Plane";
import Shopify from "./pages/Shopify";
import WooCommerce from "./pages/WooCommerce";
import Ebay from "./pages/Ebay";
import Amazon from "./pages/Amazon";
import Walmart from "./pages/Walmart";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Plane />} />
          <Route path="/shopify" element={<Shopify />} />
          <Route path="/wooCommerce" element={<WooCommerce />} />
          <Route path="/eBay" element={<Ebay />} />
          <Route path="/amazon" element={<Amazon />} />
          <Route path="/walmart" element={<Walmart />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
