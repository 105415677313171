import React, { useEffect, useRef, useState } from "react";
import "./style/navbar.css";
import { Link } from "react-router-dom";
import { FaGripLines } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiChevronRight } from "react-icons/fi";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showFixedMenuButton, setShowFixedMenuButton] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          background: "#fff",
          height: "12vh",
        }}
      >
        <div className="main_container">
          <div className="logo_container">
            <Link to="/">
              <img
                width="130px"
                src={require("../assets/DeleveryDayLogo1.png")}
              />
            </Link>
          </div>
          <div className="nav_links">
            <Link to="/pricing" className="nav_link">
              <div className="nav_Link_Item">Our Plans</div>
            </Link>
            <div
              className="menuBox"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div className="nav_Link_Item nav_link">Integrations</div>
              <div className={`dropdown ${isHovered ? "show" : "hide"}`}>
                <div className="dropdown_nav_link">
                  <Link to="/shopify">Shopify</Link>
                </div>
                <div className="dropdown_nav_link">
                  <Link to="/wooCommerce">Woocommerce</Link>
                </div>
                <div className="dropdown_nav_link">
                  <Link to="/eBay">Ebay</Link>
                </div>
                <div className="dropdown_nav_link">
                  <Link to="/amazon">Amazon</Link>
                </div>
                <div className="dropdown_nav_link">
                  <Link to="/walmart">Walmart</Link>
                </div>
              </div>
            </div>

            <Link to="/contact" className="nav_link">
              <div className="nav_Link_Item">Contact</div>
            </Link>
          </div>

          <div className="login_container">
            <div
              className="login_button"
              onClick={() => {
                window.location.href = "https://app.deleveryday.com";
              }}
            >
              Login
            </div>
            <div
              className="login_button2"
              onClick={() => {
                window.location.href = "https://app.deleveryday.com/register";
              }}
            >
              Register
            </div>
          </div>

          {isMenuOpen ? (
            <div
              className={`nav_menu_btn ${showFixedMenuButton ? "fixed" : ""}`}
              onClick={toggleMenu}
            >
              <IoClose />
            </div>
          ) : (
            <div
              className={`nav_menu_btn ${showFixedMenuButton ? "fixed" : ""}`}
              onClick={toggleMenu}
            >
              <FaGripLines />
            </div>
          )}

          {isMenuOpen && (
            <div className="fullscreen-menu">
              <div className="nav_menu_container">
                <div>
                  <Link
                    to="/pricing"
                    className="nav_menu_links"
                    onClick={toggleMenu}
                  >
                    Our Plans
                  </Link>
                  <div
                    className="nav_integration_conatiner"
                    onClick={handleClick}
                  >
                    <Link className="nav_menu_links" style={{ width: "34%" }}>
                      Integrations
                    </Link>
                    <span>
                      <FiChevronRight />
                    </span>
                  </div>
                  {open && (
                    <div
                      className="hamburgerMenu"
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          backgroundColor: "lightgray",
                        },
                      }}
                    >
                      <div onClick={toggleMenu}>
                        <Link to="/shopify" className="dropdown_nav_link">
                          Shopify
                        </Link>
                      </div>
                      <div onClick={toggleMenu}>
                        <Link to="/wooCommerce" className="dropdown_nav_link">
                          WooCommerce
                        </Link>
                      </div>
                      <div onClick={toggleMenu}>
                        <Link to="/eBay" className="dropdown_nav_link">
                          eBay
                        </Link>
                      </div>
                      <div onClick={toggleMenu}>
                        <Link to="/amazon" className="dropdown_nav_link">
                          Amazon
                        </Link>
                      </div>
                      <div onClick={toggleMenu}>
                        <Link to="/walmart" className="dropdown_nav_link">
                          Walmart
                        </Link>
                      </div>
                    </div>
                  )}
                  <Link
                    to="/contact"
                    className="nav_menu_links"
                    onClick={toggleMenu}
                  >
                    Contact
                  </Link>
                </div>

                <div className="nav_login_conatiner">
                  <div
                    className="nav_login_button1"
                    onClick={() => {
                      window.location.href = "https://app.deleveryday.com";
                    }}
                  >
                    Login
                  </div>
                  <div
                    className="nav_login_button2"
                    onClick={() => {
                      window.location.href =
                        "https://app.deleveryday.com/register";
                    }}
                  >
                    Register
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
