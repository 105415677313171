import { Grid } from "@mui/material";
import React from "react";
import "./style/contact.css";

const Contact = () => {
  return (
   
     
 <Grid
   container
   spacing={0}
   className="Quuote_Inner_ompetitive"
   sx={{ width: "90%" }}
 >
   <Grid item xl={6} lg={6} md={6} xs={12}>
     <div
       style={{
         display: "flex",
         justifyContent: "space-between",
         width: "100%",
       }}
     >
       <Grid item xl={6} lg={6} md={6} xs={12}>
         <label style={{ fontSize: "1.2rem" }}>Name</label>
         <br />
         <input type="text" required className="input_name" />
       </Grid>
       <Grid item xl={6} lg={6} md={6} xs={12}>
         <label style={{ fontSize: "1.2rem" }}>Email</label>
         <br />
         <input type="email" className="input_email" required />
       </Grid>
     </div>
     <Grid style={{ width: "100%" }}>
       <label style={{ fontSize: "1.2rem" }}>Message</label>
       <br />
       <textarea
         required
         type="text"
         name="textarea"
         rows="8"
         cols="72"
         className="input_message"
       />
     </Grid>
     <div className="contact_text" style={{}}>
       <Grid item lg={8} md={12} sm={12}>
         <p
           style={{
             width: "100%",
             fontSize: "small",
             marginTop: "15px",
             fontFamily: "poppins",
           }}
         >
           This site is protected by reCAPTCHA and the Google
           <span style={{ fontWeight: "bold" }}> Privacy Policy </span>
           and
           <span style={{ fontWeight: "bold" }}> Terms of Service </span>
           apply.
         </p>
       </Grid>
       <Grid lg={3} md={12} sm={12}>
         <button
           className="contact_btn"
           type="submit"
           style={{
             fontSize: "1.2rem",
             fontWeight: "400",
             outline: "none",
             backgroundColor: "#00d795",
             color: "#fff",
             borderRadius: "10px",
             width: "100%",
             border: "none",

             cursor: "pointer",
             "&:hover": {
              // Corrected from "&hover"
               backgroundColor: "#27efa9",
             },
           }}
         >
           Send
         </button>
       </Grid>
     </div>
   </Grid>
   <Grid
     item
     xl={6}
     lg={6}
     md={6}
     xs={12}
     style={{
       display: "flex",
       justifyContent: "center",
       flexDirection: "column",
     }}
   >
     <div style={{ fontSize: 35, fontWeight: "500", color: "black" }}>
       Connect with Us
     </div>
     <div
       style={{
         fontSize: 18,
         color: "black",
         marginTop: 25,
         marginBottom: 30,
       }}
     >
       Let's start a conversation. Use the contact form below to get in touch
       with us and we'll get back to you as soon as possible.
     </div>
   </Grid>
 </Grid>
     
 
  );
};

export default Contact;
