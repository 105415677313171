import React, { useState } from "react";
import "../components/style/shoppingCarts.css";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AmazonImg from "../assets/amazon1.png";
import WalmartImg from "../assets/mainWalmart.png";
import EbayImg from "../assets/ebay logo.png";
import WooImg from "../assets/woo1.png";

const ShoppingCard = () => {
  return (
    <>
      <Grid
        item
        xl={8}
        lg={8}
        md={8}
        xs={12}
        style={{
          // border: "1px solid #000",
          width: "90%",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="shopping_carts"
          style={{
            fontWeight: "700",
            marginBottom: "24px",
            textAlign: "center",
          }}
        >
          Seamless integrations with all major shopping carts
        </div>
        <div className="shopping_carts_imgs">
          <Link
            to="/shopify"
            className="cartsLink"
            style={{
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "200px"
              }}
              src="https://logosandtypes.com/wp-content/uploads/2020/11/shopify.svg"
            />
          </Link>
          <Link
            to="/amazon"
            className="cartsLink"
            style={{
             
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "200px",
              }}
              src={AmazonImg}
            />
          </Link>
          <Link
            to="/ebay"
            className="cartsLink"
            style={{
             
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "200px",
              }}
              src={EbayImg}
            />
          </Link>
          <Link
            to="/walmart"
            className="cartsLink"
            style={{
            
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "200px",
              }}
              src={WalmartImg}
            />
          </Link>
          <Link
            to="/wooCommerce"
            className="cartsLink"
            style={{
             
              borderRadius: "20px",
              height: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "200px",
              }}
              src={WooImg}
            />
          </Link>
        </div>
      </Grid>
    </>
  );
};

export default ShoppingCard;
