// import { Menu, MenuItem, Dropdown,MenuButton } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";
import "./style/footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer_container">
          <Grid item xl={6} lg={6} md={6} xs={12} className="Footer_Img">
            <Link to="/">
              <img
                style={{ width: "150px" }}
                src={require("../assets/deleveryimgresize.png")}
              />
            </Link>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <div className="footer_links_container">
              <div className="Links">
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  href="/pricing"
                  className="footer_links nav_Link_Item"
                >
                  Our Plans
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  className="footer_links nav_Link_Item"
                  href="/shopify"
                >
                  Shopify
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  className="footer_links nav_Link_Item"
                  href="/woocommerce"
                >
                  WooCommerce
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  className="footer_links nav_Link_Item"
                  href="/ebay"
                >
                  Ebay
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  className="footer_links nav_Link_Item"
                  href="/amazon"
                >
                  Amazon
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  className="footer_links nav_Link_Item"
                  href="/walmart"
                >
                  Walmart
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    fontSize: "1.1rem",
                    fontWeight: "500",
                  }}
                  href="/contact"
                  className="footer_links nav_Link_Item"
                >
                  <div>Contact</div>
                </a>
              </div>
            </div>
            <div className="copyrightline">
              DeleveryDay Copyright ©2024. All rights reserved.
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Footer;
