import React, { useEffect, useState } from "react";
import "./style/home.css";
import { useNavigate } from "react-router-dom";
import ServiceCard from "../components/ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Grid } from "@mui/material";
import ShoppingCard from "../components/ShoppingCart";
import Contact from "./Contact";
const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/about");
  };
  const [translateY, setTranslateY] = useState(0);
  const itemHeight = 100;
  const items = ["Think", "Design", "Build"];

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateY((prevTranslateY) => {
        const nextTranslateY = prevTranslateY - itemHeight;
        return nextTranslateY >= -itemHeight * (items.length - 1)
          ? nextTranslateY
          : 0;
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {/* ___________________________________Shipping Prices Calculator__________________________________________________ */}

      <div className="img_container">
        <div className="black_effect"></div>
        <div className="container_data">
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <div className="title">Shipping Prices Calculator</div>
            <div className="container_des">
              Introducing Deleveryday: Your One-Stop Shipping Prices Calculator
              for Fast and Accurate Courier Quotes - Save up to 70% on Courier
              rates - NO CONTRACTS - NO MINIMUM VOLUME
            </div>
            <a style={{ textDecoration: "none", color: "white" }}>
              <button
                className="container_button"
                onClick={() => {
                  window.location.href = "https://app.deleveryday.com/";
                }}
              >
                Start Now - Register Free
              </button>
            </a>
            <div className="custom-shape-divider-bottom-1702331807">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                  class="shape-fill"
                ></path>
              </svg>
            </div>
          </Grid>
        </div>
      </div>

      {/* ___________________________________Get an instant quote__________________________________________________ */}

      <div style={{ width: "100%", background: "#10d48e" }}>
        <div className="quote_instant_conatiner">
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            style={{ paddingTop: 30, paddingBottom: 60 }}
          >
            <div
              className="quote_title"
              style={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              Get an instant quote
            </div>
            <div className="quote_des">
              When it comes to shipping packages or freight across the world,
              every minute counts. Time is money, and delays can cost you both.
              That's why it's important to have a reliable and efficient courier
              or trucking service that can get your goods where they need to go
              quickly and safely. And with Deleveryday, you can get instant
              quotes for worldwide courier and European trucking, giving you a
              competitive edge in the market.
            </div>
            <div className="quote_des">
              Our platform is designed to simplify the process of getting quotes
              from multiple courier services, allowing you to compare prices and
              services in one place. We are committed to delivering exceptional
              customer service and ensuring that our clients always receive the
              best possible value for their money.
            </div>
          </Grid>
        </div>
      </div>

      {/* ___________________________________Quuote.me so competitive?__________________________________________________ */}

      <div
        // className="serviceCard1"
        style={{ width: "100%", background: "#fff" }}
      >
        <ServiceCard
          heading={" Why is Deleveryday so competitive?"}
          title1={"Leveraging Collective Volume for Savings:"}
          description1={
            "Our secret sauce lies in collective volume. With a vast network of partners and businesses, we generate an impressive daily shipment volume. This leverage empowers us to negotiate exceptional terms with our courier partners, leading to cost savings you won't find elsewhere."
          }
          title2={"Join the Deleveryday Revolution Today"}
          description2={
            " Ready to experience the Deleveryday advantage firsthand? Sign up, get an instant quote, and join our community of satisfied customers who've unlocked significant savings while benefiting from a streamlined, dependable shipping experience. The Deleveryday revolution is here—start saving today!"
          }
          onClick={() => {
            window.location.href = "https://app.deleveryday.com/";
          }}
          button={"Get Started For Free"}
          image={
            "https://www.pixground.com/wp-content/uploads/2023/07/Robot-Cargo-Delivery-AI-Generated-4K-Wallpaper-1024x576.webp"
          }
          style={{
            height: "100%",
            width: "90%",
            margin: "auto",
          }}
        />
      </div>

      {/* ___________________________________Simplify import duty & tax__________________________________________________ */}

      <div
        // className="serviceCard2"
        style={{ width: "100%", background: "#10D48E" }}
      >
        <ServiceCard
          customheading={"Simplify import duty & tax"}
          customtitle={
            "We work out all duties and taxes for your international shipments"
          }
          customdescription={
            "Duties and taxes can lead to complexities, result in customs delays, bring about unforeseen expenses, negatively impact the customer experience, and even result in the loss of clients for sellers. We ensure you're informed in advance about the costs, helping you sidestep unexpected setbacks and future shipment hold-ups."
          }
          image={
            "https://bs-uploads.toptal.io/blackfish-uploads/components/blog_post_page/content/cover_image_file/cover_image/1309720/retina_1708x683_1216_what-is-the-border-adjustment-tax-potential-benefits-and-risks-waldek_Newsletter-c533ac86c6d4e38ffec405cd3eab9005.png"
          }
          style={{
            height: "100%",
            width: "90%",
            margin: "auto",
          }}
        />
      </div>

      {/* ___________________________________Courier services__________________________________________________ */}

      <div style={{ width: "100%", background: "#fff" }}>
        <Grid
          style={{
            width: "90%",
            margin: "auto",
            paddingTop: 40,
            paddingBottom: 40,
            position: "relative",
          }}
        >
          <div
            className="quote_title"
            style={{ fontWeight: "bold", color: "black" }}
          >
            250+ Courier services
          </div>
          <div
            className="quote_des"
            style={{
              color: "#111827",
              width: "100%",
              marginTop: 20,
              marginBottom: 30,
              lineHeight: "30px",
            }}
          >
            Deleveryday brings together 250+ courier companies worldwide onto
            one platform. No more switching between sites! View rates, book
            shipments, and track packages all in one place. Fully integrated and
            streamlined for your convenience. Simplify your shipping with
            Deleveryday
          </div>
          <svg
            className="customShapeDivider"
            class="absolute bottom-0 left-0 z-50 overflow-visible"
            fill="transparent"
            height="22"
            viewBox="0 0 375 22"
          >
            <path d="M375 0L-0 22" stroke="#10D48E" stroke-width="5"></path>
          </svg>
        </Grid>
      </div>



      <div style={{ width: "100%", background: "#fff" }}>
        <Grid
          container
          spacing={0}
          className="technologySection"
          sx={{
            width: "90%",
            margin: "auto",
          }}
        >
          <Grid sx={{ width: "100%" }} item xl={6} lg={6} md={6} xs={12}>
            <div
              className="quote_title"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Use our technology for your own rates
            </div>
            <div
              className="quote_des"
              style={{
                color: "black",
                lineHeight: 4,
                marginTop: 5,
              }}
            >
              Link your own courier's account - Receive all rates on 1 platform
            </div>
            <div
              className="quote_des"
              style={{
                color: "black",
                marginTop: 5,
                marginBottom: 25,
              }}
            >
              Integrate your personal contract rates with Deleveryday's advanced
              platform. Enjoy the best of both worlds: your rates and our
              cutting-edge shipping technology
            </div>
            <a style={{ textDecoration: "none" }}>
              <button
                className="Quuote_ompetitive_button"
                style={{
                  padding: 15,
                  backgroundColor: "#10D48E",
                  color: "#ffffff",
                  borderRadius: 8,
                  cursor: "pointer",
                  border: "none",
                  "&:hover": {
                    // Corrected from "&hover"
                    backgroundColor: "#27efa9",
                  },
                }}
                onClick={() => {
                  window.location.href = "https://app.deleveryday.com/";
                }}
              >
                Explore Our Shipping Software
              </button>
            </a>
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12} sx={{ width: "100%" }}>
            <img
              className="tech_img2"
              style={{ borderRadius: 20 }}
              src={
                "https://www.simplilearn.com/ice9/free_resources_article_thumb/What_is_the_Importance_of_Technology.jpg"
              }
            />
          </Grid>
        </Grid>
      </div>

      <ShoppingCard />

      {/* ___________________________________Warehouses Network__________________________________________________ */}

      <div style={{ width: "100%", background: "#fff", height: "100%" }}>
        <Grid
          container
          spacing={0}
          className="globalSection"
          sx={{ width: "90%", margin: "auto",}}
        >
          <Grid sx={{ width: "100%" }} item xl={6} lg={6} md={6} xs={12}>
            <img
              className="tech_img"
              height={"340px"}
              style={{ borderRadius: 20 }}
              src={
                "https://www.dhl.com/content/dam/dhl/global/delivered/images/sub-story-stage-large/e124-dl-warehouse-robotics.web.745.320.jpg"
              }
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} xs={12}>
            <div
              className="network_title"
              style={{ fontWeight: "bold", color: "black" }}
            >
              Access our Global Warehouses Network
            </div>
            <div
              className="network_des"
              style={{
                color: "black",
                marginTop: 25,
                marginBottom: 30,
              }}
            >
              With the help of our extensive network of 3PL partners, decreasing
              shipping costs, shortening transit times, and reducing tariff
              exposure have never been simpler. Our shipping experts will create
              a fulfilment plan specifically for your company.
            </div>
            <a
              target="_blank"
              href="https://app.deleveryday.com/"
              style={{ textDecoration: "none" }}
            >
              <button
                className="network_btn"
                style={{
                  backgroundColor: "#10D48E",
                  color: "#ffffff",
                  borderRadius: 8,
                  cursor: "pointer",
                  border: "none",
                  fontFamily: "poppins",
                  "&:hover": {
                    // Corrected from "&hover"
                    backgroundColor: "#27efa9",
                  },
                }}
              >
                More Info
              </button>
            </a>
          </Grid>
        </Grid>
      </div>

      {/* ___________________________________Slider 2__________________________________________________ */}
     
     
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg
          class="absolute bottom-0 left-0 z-50 overflow-visible"
          fill="transparent"
          width="90%"
          height="20"
          viewBox="0 0 375 6"
        >
          <path d="M0 6H375" stroke="#10D48E" stroke-width="6"></path>
        </svg>
      </div>

      {/* ___________________________________Contact__________________________________________________ */}
      <Contact />
    </div>
  );
};

export default Home;
